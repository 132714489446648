const ICONS = {
  // Menu
  home: 'mdi-home',
  customers: 'mdi-account-group',
  quotations: 'mdi-file',
  invoices: 'mdi-file-document',
  finance: 'mdi-scale-balance',
  reports: 'mdi-chart-line',
  communication: 'mdi-email',
  faq: 'mdi-book-search',
  settings: 'mdi-cog',
  switch_on: 'mdi-toggle-switch-outline',
  switch_off: 'mdi-toggle-switch',
  money: 'mdi-currency-eur',

  user: 'mdi-account',
  logout: 'mdi-power',
  user_card: 'mdi-card-account-details-outline',
  sync: 'mdi-sync-alert',
  grant_right: 'mdi-account-plus',
  remove_right: 'mdi-account-minus',

  warning: 'mdi-alert-decagram',
  error: 'mdi-alert-circle',

  customer: 'mdi-account-outline',

  company: 'mdi-domain',

  // Other icons
  customerSheet: 'mdi-clipboard-account',
  newQuotation: 'mdi-text-box-plus',
  email: 'mdi-email',
  delete: 'mdi-delete',
  print: 'mdi-printer',
  edit: 'mdi-pencil',
  save: 'mdi-content-save',
  cancel: 'mdi-close-circle',
  payment: 'mdi-cash-multiple',
  hashtag: 'mdi-pound',
  phone: 'mdi-phone',
  calendar: 'mdi-calendar',
  clock: 'mdi-clock-outline',
  lock: 'mdi-lock',
  unlock: 'mdi-lock-open-variant',
  visibility: 'mdi-eye',
  visibility_off: 'mdi-eye-off',
  valid: 'mdi-check-circle-outline',
  invalid: 'mdi-close-circle-outline',
  companyCreate: 'mdi-folder-edit-outline',
  close: 'mdi-close',
  add: 'mdi-plus'

}

export default ICONS
