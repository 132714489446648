<template>
  <v-container container--fluid>
    <iframe src="https://i.simmer.io/@DonatienG/battleline" style="width:75vw;height:80vh"></iframe>
  </v-container>
</template>

<script>
  export default {
    name: 'SimmerGame',

    data: () => ({
    }),
  }
</script>
