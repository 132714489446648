export default {
  'title': 'Battle Line',
  'welcome-title': 'Bienvenue sur Battle Line !',
  'game-details': 'BattleLine est un jeu de cartes où vous devez capturer des drapeaux en déployant vos troupes !<br /> Dans un style de poker, vous devrez ajouter une carte à l\'un des drapeaux à chaque tour, et créer des combinaisons plus fortes que vos adversaires.<br /><br />',
  'credits': 'Remerciement spécial aux bons copains m\'ayant offert ce jeu, qui m\'a donné envie de le développer pour pouvoir y jouer ensemble.',
  'buttons': {
    'play': 'JOUER',
    'quit': 'RETOUR'
  },
  'maintenance': 'Maintenance',
  'maintenance-details': 'Le jeu est actuellement en maintenance, pour environ {0} minutes.',
  'oops': 'Oups',
  'mobile-game': 'Le jeu n\'est pas accessible sur téléphone depuis le site internet. Si vous possédez un téléphone Android, vous pouvez installer le jeu depuis le Play Store. Le jeu n\'est malheureusement pas encore disponible pour les autres plateforme, vous devrez donc jouer depuis un ordinateur.',
  'download-on-store': 'Télécharger sur le Play Store'
}