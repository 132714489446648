<template>
  <v-app>
    <v-main :class="'app-body grey lighten-4'">
      <Game />
    </v-main>
  </v-app>
</template>

<script>
import Game from '@/views/Game.vue'

export default {
  name: 'App',
  components: {
    Game
  },

  computed: {
  },

  data: () => ({
    isPlayingGame: false
  }),

  created: function () {
  },
  methods: {
  },
  watch: {
  }
}
</script>


<style lang="scss">
@import '@/scss/index.scss';
</style>
