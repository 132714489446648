import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
  },

  state: {
    playGame: false
  },

  actions: {
  },
  getters: {
    playGame: state => state.playGame,
    appVersion: () => {
      var pjson = require('../../package.json')
      if (!pjson) {
        return ''
      }
      return pjson.version
    }
  },
  mutations: {
    setPlayGame (state, play) {
      state.playGame = play
    }
  }

})

export default store
