export default {
  'title': 'Battle Line',
  'welcome-title': 'Welcome to Battle Line !',
  'game-details': 'BattleLine is a card game where you have to capture flags by deploying your troops!<br />In a poker style, you will have to add a card to one of the flags each turn, and create stronger combinations than your opponnent.<br /><br />',
  'credits': 'Special thanks to the good friends who gave me this game, which made me want to develop it to be able to play it together.',
  'buttons': {
    'play': 'PLAY',
    'quit': 'BACK'
  },
  'maintenance': 'Maintenance',
  'maintenance-details': 'Le jeu est actuellement en maintenance, pour environ {0} minutes.',
  'oops': 'Oops',
  'mobile-game': 'The game is not accessible by telephone from the website. If you have an Android phone, you can install the game from the Play Store. Unfortunately, the game is not yet available for other platforms, so you will have to play the game from a computer.',
  'download-on-store': 'Download on Play Store'
}