<template>
  <v-container container--fluid fill-height>
    <v-layout wrap>
      <v-flex xs12 text-center>
        <SimmerGame />
      </v-flex>
      <!-- <v-flex xs12>
        <v-btn
          color="secondary"
          elevation="4"
          large
          class="orange--text text--lighten-4 headline font-weight-bold"
          :to="{ name: 'Home' }"
        >Back</v-btn>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>

import SimmerGame from '@/components/SimmerGame'

export default {
  components: {
    SimmerGame
  },
  mounted () {
  },

  data () {
    return {
    }
  },

  computed: {
  },

  watch: {
  },

  created () {
  },

  methods: {
  }
}
</script>
