import Vue from 'vue'
import VueResource from 'vue-resource'
import VueJWT from 'vuejs-jwt'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import store from './store/store'

Vue.use(VueJWT)
Vue.use(VueResource)

Vue.config.productionTip = false

new Vue({
  vuetify,
  http: {
    root: '/'
  },
  store,
  render: h => h(App)
}).$mount('#app')
