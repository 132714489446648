import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import fr from './i18n/fr.ts'
import en from './i18n/en.ts'

import icons from './icons'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false
  },
  icons: {
    iconfont: 'mdi', // default
    values: icons
  },
  lang: {
    locales: { fr, en }
  }
});
